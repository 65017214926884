/* ContactForm.css */

.contact-form-container {
  margin: 20px auto;
  max-width: 500px;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.thank-you-message {
  text-align: center;
}

.form-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #555;
}

.form-input, .form-textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.form-textarea {
  height: 150px;
  resize: vertical;
}

.form-button {
  display: block;
  width: 50%;
  margin: 20px auto;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
}

.form-button:hover {
  background-color: #0056b3;
}

p {
  color: #666; /* For the additional line of text */
}
